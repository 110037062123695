import React from "react";
import { GridContainer } from "../Layout/GridContainer";
import { styled } from "../../styles";
import { GridItem } from "../Layout/GridItem";
import { Caption } from "../Typography/Typography";

const Container = styled(GridContainer, {
  backgroundColor: "$accent2Shade4",
  borderRadius: "8px",
  padding: "$2 $1",
});

export type FormErrorType = string[] | object[] | undefined;

export type FormErrorsProps = {
  errors: FormErrorType;
};

export const FormErrors = ({ errors: baseErrors }: FormErrorsProps) => {
  if (!Array.isArray(baseErrors) || baseErrors.length === 0) return null;

  const errors = baseErrors.map((err) => {
    if (typeof err === "string") {
      return err;
    }

    if (typeof err === "object" && "message" in err && "field" in err) {
      const errField = Array.isArray(err.field)
        ? err.field.join(" ")
        : err.field;
      const friendlyFieldName = (errField as string)
        .replace(/_id$/, "")
        .replace(/_/g, " ")
        .replace(/^\w/, (c) => c.toUpperCase());

      return `${friendlyFieldName}: ${err.message}`;
    }

    if (typeof err === "object") {
      return Object.values(err).join(". ");
    }
  });

  return (
    <Container>
      {errors.map((error) => {
        return (
          <GridItem key={error}>
            <Caption
              css={{
                color: "$white",
              }}
            >
              {error}
            </Caption>
          </GridItem>
        );
      })}
    </Container>
  );
};
